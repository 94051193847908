/* eslint-disable complexity */
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { IconButton } from '@/components/WithTracking/IconButton';
import { CommonTrackActions } from '@/constants/analytics';
import { UploadType } from '@/constants/media';
import { useNumberFormatter } from '@/hooks/formatters/useNumberFormatter';
import { CircledCloseIcon } from '@/icons/CircledCloseIcon';
import { StopIcon } from '@/icons/StopIcon';
import { SuccessGreen } from '@/icons/SuccessGreen';
import { VectorsIcon } from '@/icons/VectorsIcon';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

import { UPLOADS_VALID_VECTOR_FILE_TYPES } from './constants';
import { TOO_LARGE, UNSUPPORTED, UploadStates } from './Context/constants';
import { uploadItemStyle } from './UploadItem.styles';
import { getMaxSize, getSubTextKey } from './utils';

const ICON_SIZE = 16;

const ThumbnailImage = styled('img')(() => ({ height: ICON_SIZE, width: ICON_SIZE }));
const ThumbnailVideo = styled('video')(() => ({ height: ICON_SIZE, width: ICON_SIZE }));

export const UploadItem = ({
  name,
  state,
  onCancel,
  onRetry,
  processStatus,
  mediaType,
  failedReason,
  thumbnailUrl,
  type,
}) => {
  const localizedMaxMB = useNumberFormatter({ style: 'unit', unit: 'megabyte', unitDisplay: 'narrow' });
  const localizedMaxGB = useNumberFormatter({ style: 'unit', unit: 'gigabyte', unitDisplay: 'narrow' });
  const theme = useTheme();
  const withHoverCancel = state === UploadStates.Uploading;
  const withRetryAction = onRetry && (state === UploadStates.Canceled || state === UploadStates.Errored);
  const withCancelAction = state === UploadStates.Queued;
  const { t } = useTranslation(NAMESPACE_COMMON);

  const fileExtension = name.split('.').pop();

  const subTextKey = getSubTextKey(state, failedReason, processStatus);

  const isUnsupportedFile = failedReason === UNSUPPORTED;
  const isFileTooLarge = failedReason === TOO_LARGE;

  let failedUploadInterpolate;

  if (isUnsupportedFile) {
    failedUploadInterpolate = { FILETYPE: fileExtension };
  }

  if (isFileTooLarge) {
    failedUploadInterpolate = { MAXSIZE: getMaxSize(mediaType, localizedMaxMB, localizedMaxGB) };
  }

  const hoverCancelStyle = () => {
    if (withHoverCancel) {
      return uploadItemStyle(theme, state).withHoverCancel;
    }

    return {};
  };

  const renderThumbnail = () => {
    const [fileType] = type.split('/');

    if (fileType === UploadType.Image && !UPLOADS_VALID_VECTOR_FILE_TYPES.includes(type)) {
      return <ThumbnailImage data-testid="imageThumbnail" src={thumbnailUrl} />;
    }

    if (fileType === UploadType.Video) {
      return <ThumbnailVideo data-testid="videoThumbnail" src={`${thumbnailUrl}#t=1`} />;
    }

    return <VectorsIcon data-testid="vectorThumbnail" style={{ fontSize: ICON_SIZE }} />;
  };

  const classes = uploadItemStyle(theme);

  return (
    <Box sx={[classes.uploadSnackbarItem, hoverCancelStyle()]}>
      <Box sx={classes.content}>
        {thumbnailUrl ? (
          renderThumbnail()
        ) : (
          <Box sx={classes.thumbnailPlaceholder} data-testid="thumbnailPlaceholder" />
        )}
        <Typography noWrap sx={classes.name} variant="body2" data-testid="fileName">
          {name}
        </Typography>
      </Box>
      <Box sx={classes.rightAdornment} data-testid="upload status">
        <Typography variant="body2" sx={classes.subtext}>
          {subTextKey && t(subTextKey, failedUploadInterpolate)}
        </Typography>
        {withRetryAction && (
          <ButtonBase sx={classes.link} onClick={onRetry}>
            {t('retry')}
          </ButtonBase>
        )}
        {withCancelAction && (
          <ButtonBase sx={classes.link} onClick={onCancel}>
            {t('cancel')}
          </ButtonBase>
        )}
        <Box sx={classes.iconContainer}>
          <IconButton
            sx={classes.cancelIcon}
            disableRipple
            size="small"
            onClick={onCancel}
            trackAction={CommonTrackActions.cancel}
          >
            <CircledCloseIcon />
          </IconButton>

          <Box sx={classes.statusIcon}>
            {state === UploadStates.Done && <SuccessGreen fontSize="small" />}
            {(state === UploadStates.Errored || state === UploadStates.Canceled) && (
              <StopIcon fontSize="small" data-automation="UploadItem_failed" />
            )}
            {state === UploadStates.Uploading && (
              <Box sx={classes.circularContainer}>
                <Typography sx={{ paddingRight: 1 }} variant="body2">
                  {t('uploading')}
                </Typography>
                <CircularProgress data-testid="upload-circular-progress" color="inherit" size={ICON_SIZE} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
