export const uploadSnackStyles = ({ palette, zIndex, spacing, breakpoints }) => {
  const borderRadius = spacing(2);

  // MUI specificity overrides
  const accordion = {
    accordionRoot: {
      '&&': {
        margin: 0,
        borderRadius: `${spacing(2.5)} ${spacing(2.5)} ${borderRadius} ${borderRadius}`,
      },
    },
  };

  // MUI specificity overrides
  const summary = {
    summaryRoot: {
      background: palette.primary?.main,
      color: palette.white?.main,
      padding: `0 ${spacing(4.5)} 0 ${spacing(2)}`,
      '&&': {
        borderRadius: `${borderRadius} ${borderRadius} 0 0`,
        minHeight: spacing(6),
      },
    },
    summaryExpandIcon: {
      color: palette.primary?.main,
    },
    summaryContent: {
      '&&': {
        margin: 0,
        minWidth: 0,
      },
    },
  };

  return {
    uploadSnackbar: {
      position: 'fixed',
      bottom: spacing(4.5),
      right: spacing(4.5),
      zIndex: zIndex.drawer - 1,
      borderRadius,
      width: 360,
      [breakpoints.down('sm')]: {
        width: '100%',
        right: 0,
        left: 0,
        margin: '0 auto',
      },
      boxShadow:
        '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    },
    closeButton: {
      position: 'absolute',
      zIndex: 1,
      right: 0,
      top: 0,
      color: palette.primary?.main,
    },
    ...accordion,
    ...summary,
    accordionDetails: {
      padding: 0,
      flexDirection: 'column',
      overflow: 'hidden',
      borderRadius: `0 0 ${borderRadius} ${borderRadius}`,
    },
    accordionDetailsInner: {
      maxHeight: 56 * 5,
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
  };
};
