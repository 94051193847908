import { DUPLICATE, TOO_LARGE, UploadStates, VIRUS } from '@/components/UploadSnackbar/Context/constants';
import { localisedType, UploadList, UploadStateType } from '@/components/UploadSnackbar/types';
import { MediaOfTypeImageOrVideo, MediaType } from '@/constants/media';

export const getSubTextKey = (state: UploadStateType, failedReason?: string, processStatus = '') => {
  let subText;

  if (state === UploadStates.Errored) {
    if (processStatus === VIRUS) {
      subText = 'upload_virus_detected';
    } else if (failedReason === TOO_LARGE) {
      subText = 'file_size_limit';
    } else if (failedReason === DUPLICATE) {
      subText = 'duplicate';
    } else {
      subText = 'unsupported_file';
    }
  }

  if (state === UploadStates.Canceled) {
    subText = 'upload_cancelled';
  }

  if (state === UploadStates.Queued) {
    subText = 'upload_queued';
  }

  return subText;
};

export const getMaxSize = (mediaType: string, localizedMaxMB: localisedType, localizedMaxGB: localisedType) => {
  if (mediaType === 'images/jpeg' || mediaType === 'images/jpg') {
    return localizedMaxMB(50);
  }

  if (mediaType === 'application/postscript') {
    return localizedMaxMB(100);
  }

  if (mediaType === 'video/mp4' || mediaType === 'video/mov') {
    return localizedMaxGB(4);
  }

  return localizedMaxMB(50);
};

export const getMediaTypeFromMedia = ({ media }: { media: MediaOfTypeImageOrVideo }) =>
  media && media.includes(MediaType.Video) ? MediaType.Video : MediaType.Photo;

/**
 * Returns the last uploaded media type from a given upload list
 * If all are equal just returns the type of the first meda item in the list
 * If unequal, return whatever the last uploaded item was
 * @param {Object=} UploadList
 * @returns {MediaOfTypeImageOrVideo}
 */
export const getLastUploadedMediaType = ({ uploadList }: { uploadList: UploadList[] }): MediaOfTypeImageOrVideo => {
  const allEqual = uploadList.every((uploadedItem) => uploadedItem.mediaType === uploadList[0].mediaType);

  if (allEqual) return getMediaTypeFromMedia({ media: uploadList[0].mediaType });

  return getMediaTypeFromMedia({ media: uploadList[uploadList.length - 1].mediaType });
};
