import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const StopIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 15.586L15.586 20H8.414L4 15.586V8.414L8.414 4H15.586L20 8.414V15.586ZM16.414 2H7.586L2 7.586V16.414L7.586 22H16.414L22 16.414V7.586L16.414 2ZM11 18.0001H13V16.0001H11V18.0001ZM13 14.0001H11V6.00009H13V14.0001Z"
    />
  </SvgIcon>
);
