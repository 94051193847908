export const uploadItemStyle = ({ palette, spacing, tokens: { animation, color } = {} }) => ({
  uploadSnackbarItem: {
    display: 'flex',
    padding: `${spacing(1)} ${spacing(0.5)} ${spacing(1)} ${spacing(2)}`,
    width: '100%',
    borderTop: `solid 1px ${palette.border1Color}`,
    color: palette.text.secondary,
  },
  content: {
    minWidth: 0,
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
  },
  name: {
    paddingLeft: spacing(1),
  },
  rightAdornment: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacing(2),
  },
  iconContainer: {
    minWidth: spacing(5),
    minHeight: spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelIcon: {
    display: 'none',
  },
  statusIcon: {
    display: 'flex',
  },
  done: {},
  errored: {},
  pending: {},
  canceled: {},
  withHoverCancel: {
    '&:hover': {
      background: palette.action.selected,
      transition: `${animation?.preset['ease-in']}`,
    },
  },
  link: {
    textDecoration: 'none',
    verticalAlign: 'baseline',
    fontSize: 'inherit',
    '&, &:visited': {
      color: palette.info.main,
    },
    '&:hover': {
      color: palette.text.primary,
    },
  },
  subtext: {
    whiteSpace: 'nowrap',
    paddingLeft: spacing(1),
    paddingRight: spacing(0.5),
    display: 'block',
  },
  queued: {},
  uploading: {},
  successIcon: { backgroundColor: color?.green?.jungle },
  thumbnailPlaceholder: { minHeight: 16, minWidth: 16, backgroundColor: palette.background.inactive },
  circularContainer: { display: 'flex', alignItems: 'center', marginRight: 1.5 },
});
