import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SuccessGreen = (props: Partial<SvgIconProps>) => (
  <SvgIcon {...props}>
    <path
      d="M12 2C10.0222 2 8.08879 2.58649 6.44429 3.6853C4.7998 4.78412 3.51808 6.3459 2.7612 8.17317C2.00433 10.0004 1.80629 12.0111 2.19214 13.9509C2.578 15.8907 3.5304 17.6725 4.92893 19.0711C6.32745 20.4696 8.10928 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9996 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM17.7998 8.59961L11.7998 16.5996C11.714 16.7143 11.6046 16.8092 11.4789 16.8777C11.3532 16.9463 11.2141 16.987 11.0713 16.9971C11.0469 16.999 11.0234 17 11 17C10.8687 17.0001 10.7386 16.9742 10.6173 16.9239C10.496 16.8736 10.3858 16.7999 10.293 16.707L6.293 12.707C6.10829 12.5189 6.00533 12.2655 6.00652 12.0019C6.00771 11.7383 6.11296 11.4858 6.29937 11.2994C6.48578 11.113 6.73826 11.0077 7.00187 11.0065C7.26549 11.0053 7.51891 11.1083 7.707 11.293L10.8916 14.4776L16.2002 7.40042C16.3595 7.18914 16.5961 7.04966 16.8581 7.01255C17.1201 6.97545 17.3862 7.04375 17.5979 7.20248C17.8096 7.36122 17.9498 7.59743 17.9876 7.85933C18.0255 8.12124 17.9579 8.38746 17.7998 8.59964L17.7998 8.59961Z"
      fill="#26BD7E"
    />
  </SvgIcon>
);
