import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CircledCloseIcon = (props: Partial<SvgIconProps>) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.707 15.293L15.293 16.707L12 13.414L8.707 16.707L7.293 15.293L10.586 12L7.293 8.707L8.707 7.293L12 10.586L15.293 7.293L16.707 8.707L13.414 12L16.707 15.293ZM12 2C6.477 2 2 6.478 2 12C2 17.522 6.477 22 12 22C17.523 22 22 17.522 22 12C22 6.478 17.523 2 12 2V2Z"
    />
  </SvgIcon>
);
