import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { UploadProgressProps } from '@/components/UploadSnackbar/types';
import { Link } from '@/components/WithTracking';
import { CommonTrackActions } from '@/constants/analytics';
import { SITE_ROUTES } from '@/constants/global';
import { MediaType } from '@/constants/media';
import { useNumberFormatter } from '@/hooks/formatters/useNumberFormatter';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

const uploadProgressStyles = ({ palette, spacing, tokens: { font } }: Theme) => ({
  barEnterprise: {
    background: `linear-gradient(270deg, #A38657 0%, #E8D568 100%)`,
    borderRadius: 0,
  },
  barSstk: {
    backgroundColor: palette.secondary.main,
    borderRadius: 0,
  },
  uploadProgress: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  progressRoot: {
    '&&': {
      borderRadius: 0,
      background: palette.border1Color,
    },
  },
  header: {
    minHeight: spacing(5),
    display: 'flex',
    alignItems: 'center',
  },
  leftCol: {
    paddingLeft: spacing(2),
    flex: '1 1 auto',
  },
  rightCol: {
    paddingRight: spacing(2),
    flex: '1 1 auto',
    textAlign: 'right',
  },
  link: {
    textDecoration: 'none',
    fontSize: font?.size?.s,
    '&, &:visited': {
      color: palette.info.main,
    },
    '&:hover': {
      color: palette.text.primary,
    },
  },
  actionLink: {
    marginTop: 'auto',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export const UploadProgress = ({
  progress,
  isSticky,
  allCompleted,
  lastUploadedMediaType = MediaType.Photo,
}: UploadProgressProps) => {
  const theme = useTheme();
  const styles = uploadProgressStyles(theme);
  const percentFormatter = useNumberFormatter({ style: 'percent' }); // TODO - Replace with next-web version, they are probably different.
  const { t } = useTranslation(NAMESPACE_COMMON);

  return (
    <Paper sx={styles.uploadProgress} square elevation={isSticky ? 4 : 0}>
      <Box sx={styles.header}>
        <Box sx={styles.leftCol}>
          <Typography variant="body2">{percentFormatter(Math.round(progress * 100) / 100)}</Typography>
        </Box>
        {allCompleted && (
          <Box sx={styles.rightCol}>
            <Typography variant="body2">
              <Link
                href={`${SITE_ROUTES.PORTFOLIO_LANDING_PAGE_NO_MEDIA_TYPE}/${lastUploadedMediaType}`}
                trackAction={CommonTrackActions.uploadGoToPortfolio}
              >
                {t('go_to_portfolio')}
              </Link>
            </Typography>
          </Box>
        )}
      </Box>
      <LinearProgress sx={[styles.barSstk, styles.progressRoot]} variant="determinate" value={progress * 100} />
    </Paper>
  );
};
